import Vue from 'vue'
import App from './App.vue'
import { i18n } from "@/assets/js/vue-i18n-init"
import router from './router'
import Default from '@/components/default'
Vue.component('Default', Default)
import VuePdfApp from "vue-pdf-app";
Vue.config.productionTip = false
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
Vue.component("Hooper",Hooper)
Vue.component("vue-pdf-app", VuePdfApp);
Vue.component("Slide",Slide)
new Vue({
  router,
  i18n,
  render: function (h) { return h(App) },
}).$mount('#app')
