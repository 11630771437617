
<template>
  <div class="">
    <button class="plus" @click="scale">
      +
    </button>
    <div class="carousel" :class="{ scalling: scalling }">
      <div v-if="count>0" class="icons-next" @click.prevent="plus">
        <img src="@/assets/icons/arrow.svg" alt="" />
      </div>
      <div class="icons-prev" @click.prevent="minus">
        <img src="@/assets/icons/arrow.svg" alt="" />
      </div>
      <div
        class="item"
        v-for="(item, index) in items"
        :key="index"
        :style="
          'transform:rotate(' +
          30 * count +
          'deg) translate(' +
          item.translate +
          ')'
        "
      >
        <div class="child" :style="'transform:rotate(' + -30 * count + 'deg)'">
          <img :src="item.img" />
          <span>{{ item.text[$i18n.locale] }}</span>
        </div>
      </div>
    </div>
        <div class="next__prev">
          <button class="next_prev_l" @click="minus"><img src="@/assets/icons/next_prev.svg" alt=""></button>
          <button class="next_prev_r" @click="plus"><img src="@/assets/icons/next_prev.svg" alt=""></button>
        </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      count: 0,
      section: 0,
      scrolled: 0,
      scalling: false,
      items: [
        {
          img: require("@/assets/img/passport.png"),
          text: {ru:"Памятка туриста",en:"Tourist memo",uz:'Turistik eslatma'},
          translate: "-335px,-295px",
        },
        {
          img: require("@/assets/img/6c191b3de6a40f6568eaa02e73bfce9d2 1 1.png"),
          text: {ru:"Онлайн услуги банков",en:"Online banking services",uz:'Onlayn bank xizmatlari'},
          translate: "-475px,-130px",
        },
        {
          img: require("@/assets/img/widget_1569244024_4999 2.png"),
          text: {ru:"Расположение банкоматов ",en:"Location of ATMs",uz:'Bankomatlarning joylashuvi'},
          translate: "-485px,120px",
        },
        {
          img: require("@/assets/img/1030520982 2.png"),
          text: {ru:"Аренда авто и многое другое",en:"Car rental and more",uz:'Avtomobil ijarasi va boshqalar'},
          translate: "-340px,320px",
        },
        {
          img: require("@/assets/img/anyconv 1.png"),
          text: {ru:"Покупка авиа-билетов",en:"Buying air tickets",uz:'Aviachiptalarni sotib olish'},
          translate: "-110px,460px",
        },
        {
          img: require("@/assets/img/Talgo-250_UTY-Afrosiyob_Railcolornews_0384 2.png"),
          text: {ru:"Покупка ж/д билетов",en:"Buying railway tickets",uz:'Temir yo\'l chiptalarini sotib olish'},
          translate: "110px,460px",
        },
        {
          img: require("@/assets/img/сумка узбекистан 1.png"),
          text: {ru:"Турпакеты для активного туризма",en:"Tour packages for active tourism",uz:'Faol turizm uchun tur paketlar'},
          translate: "340px,320px",
        },
        {
          img: require("@/assets/img/1853395_original 1.png"),
          text: {ru:"Информация о достопримечательностях",en:"Information about sightseeing",uz:'Diqqatga sazovor maskan haqida ma\'lumot'},
          translate: "460px,120px",
        },
        {
          img: require("@/assets/img/figurka-keramicheskaya-topchan-kruglyy-14-sm-miks_0c58dea97aca0fc_1024x3000.png"),
          text: {ru:"Расположение сувенирных магазинов",en:"Location of souvenir shops",uz:'Suvenir do\'konlarining joylashuvi'},
          translate: "460px,-120px",
        },
        {
          img: require("@/assets/img/gestion_reservas 1.png"),
          text: {ru:"Бронирование номеров в гостиницах",en:"Booking hotel rooms",uz:'Mehmonxona xonalarini bron qilish'},
          translate: "340px,-320px",
        },
        {
          img: require("@/assets/img/1645301803_31-phonoteka-org-p-fon-dlya-turisticheskogo-saita-33 1.png"),
          text: {ru:"Страхования во время путешествия",en:"Travel insurance",uz:'Sayohat sug\'urtasi'},
          translate: "110px,-460px",
        },
        {
          img: require("@/assets/img/spoon-knife-and-fork-with-red-ribbon 1.png"),
          text: {ru:"Информация о кафе и ресторанах",en:"Information about cafes and restaurants",uz:'Kafe va restoranlar haqida '},
          translate: "-110px,-460px",
        },
        {
          img: require("@/assets/img/AdobeStock_418085646_Preview.png"),
          text: {ru:"Доставки",en:"Delivery",uz:'Yetkazib berish'},
          translate: "-110px,-460px",
        },
      ],
    };
  },
  props:{
    slide:{
      type: Number,
      default() {
                return 1;
            },
    },
    ref_:{
      type: Object,
      default() {
                return {};
            },
    }
  },
  watch: {
    slide(){
      this.count = this.slide
    },
    count() {
      if (this.count > 6&& this.count<15) {
        this.items[0].text = {ru:"Профессиональные гиды",en:"Professional guides",uz:'Professional gidlar va yo’lboshlovchilar'};
        this.items[0].img= require("@/assets/img/6e03df6176b6bb51f91784e04fd76130 1.png")
        // this.items[1].text = "Доставки";
        // this.items[1].img = require("@/assets/img/AdobeStock_418085646_Preview.png");
        this.items[1].text = {ru:"Памятка туриста",en:"Tourist memo",uz:'Turistik eslatma'};
        this.items[1].img = require("@/assets/img/passport.png");
        this.items[2].text = {ru:"Онлайн услуги банков",en:"Online banking services",uz:'Onlayn bank xizmatlari'};
        this.items[2].img= require("@/assets/img/6c191b3de6a40f6568eaa02e73bfce9d2 1 1.png")
        this.items[3].text = {ru:"Расположение банкоматов ",en:"Location of ATMs",uz:'Bankomatlarning joylashuvi'};
        this.items[3].img = require("@/assets/img/widget_1569244024_4999 2.png");
      } else {
        // this.items[11].text = "Профессиональные гиды";
        // this.items[11].img= require("@/assets/img/6e03df6176b6bb51f91784e04fd76130 1.png")
        this.items[0].text = {ru:"Памятка туриста",en:"Tourist memo",uz:'Turistik eslatma'};
        this.items[0].img = require("@/assets/img/passport.png");
        this.items[1].text = {ru:"Онлайн услуги банков",en:"Online banking services",uz:'Onlayn bank xizmatlari'};
        this.items[1].img= require("@/assets/img/6c191b3de6a40f6568eaa02e73bfce9d2 1 1.png")
        this.items[2].text = {ru:"Расположение банкоматов ",en:"Location of ATMs",uz:'Bankomatlarning joylashuvi'};
        this.items[2].img = require("@/assets/img/widget_1569244024_4999 2.png");
        this.items[3].text = {ru:"Аренда авто и многое другое",en:"Car rental and more",uz:'Avtomobil ijarasi va boshqalar'};
        this.items[3].img = require("@/assets/img/1030520982 2.png");
        // this.items[2].text = "Онлайн услуги банков";
        // this.items[2].img= require("@/assets/img/6c191b3de6a40f6568eaa02e73bfce9d2 1 1.png")
        // this.items[1].img = require("@/assets/img/6c191b3de6a40f6568eaa02e73bfce9d2 1 1.png");
      }
      if (window.innerWidth < 1281 && window.innerWidth > 1101) {
        console.log(this.count);
        switch (this.count) {
          case 0:this.items[0].translate = "-310px, -290px";this.items[1].translate = "-465px,-160px";this.items[12].translate = "-120px, -320px";break;
          case 1:this.items[1].translate = "-465px,-125px";this.items[2].translate = "-500px,80px";this.items[12].translate = "-300px, -240px";break;
          case 2:this.items[2].translate = "-465px,125px";this.items[3].translate = "-390px,320px";break;
          case 3:this.items[3].translate = "-340px,330px";this.items[4].translate = "-185px,460px";break;
          case 4:this.items[4].translate = "-125px,460px";this.items[5].translate = "75px,500px";break;
          case 5:this.items[5].translate = "115px,455px";this.items[6].translate = "310px,395px";break;
          case 6:this.items[6].translate = "330px,325px";this.items[7].translate = "460px,180px";break;
          case 7:this.items[7].translate = "450px,125px";this.items[8].translate = "500px,-75px";break;
          case 8:this.items[8].translate = "460px,-125px";this.items[9].translate = "385px,-320px";break;
          case 9:this.items[9].translate = "340px,-325px";this.items[10].translate = "175px,-460px";break;
          case 10:this.items[10].translate = "115px, -425px";this.items[11].translate = "-88px,-480px";this.items[12].translate = "-250px, -360px";break;
          case 11:this.items[11].translate = "-120px, -430px";this.items[12].translate = "-450px, -185px";this.items[0].translate = "-315px, -385px";break;
          case 12:this.items[0].translate = "-325px, -300px";this.items[12].translate = "-470px, -150px";this.items[1].translate = "-470px,65px";break;
          case 13:this.items[0].translate = "-270px, -240px";this.items[1].translate = "-495px,85px";this.items[2].translate = "-390px, 300px";this.items[12].translate = "-440px, -120px";break;
          case 14: this.items[0].translate = "-310px,-255px";this.items[1].translate = "-430px, 140px";this.items[2].translate = "-390px, 330px";this.items[3].translate = "-190px, 470px";break;
          case -1:this.items[0].translate = "-330px, -360px";this.items[11].translate = "90px, -380px";this.items[12].translate = "-95px, -405px";break;
          // case 11:this.items[11].translate = "-125px,-460px";this.items[12].translate = "-315px,-390px";break;
          // case 12:this.items[12].translate = "-340px,-320px";this.items[0].translate = "-460px,-170px";break;
          // case 13:this.items[0].translate = "-465px,-125px";this.items[1].translate = "-495px,85px";break;
          // case 14:this.items[0].translate = "-465px,125px";this.items[1].translate = "-395px,325px";break;
        }
      }
      else if (window.innerWidth < 1100) {
        switch (this.count) {
          case 0:this.items[0].translate = "-310px,-255px";this.items[1].translate = "-419px, -155px";break;
          case 1:this.items[1].translate = "-400px, -80px";this.items[2].translate = "-435px, 80px";break;
          case 2:this.items[2].translate = "-387px, 135px";this.items[3].translate = "-345px, 285px";break;
          case 3:this.items[3].translate = "-275px, 330px";this.items[4].translate = "-155px, 425px";break;
          case 4:this.items[3].translate = "-290px, 300px";this.items[4].translate = "-88px, 420px";this.items[5].translate = "70px, 455px";break;
          case 5:this.items[5].translate = "125px, 410px";this.items[6].translate = "275px, 355px";break;
          case 6:this.items[6].translate = "310px, 290px";this.items[7].translate = "415px, 164px";break;
          case 7:this.items[7].translate = "413px, 80px";this.items[8].translate = "434px, -69px";break;
          case 8:this.items[7].translate = "390px,100px";this.items[8].translate = "390px,-125px";this.items[9].translate = "340px,-285px";break;
          case 9:this.items[8].translate = "390px,-110px";this.items[9].translate = "275px, -315px";this.items[10].translate = "150px, -420px";break;
          case 10:this.items[10].translate = "270px, -250px  ";this.items[10].translate = "77px, -400px  ";this.items[11].translate = "-78px,-435px";break;
          case 11:this.items[11].translate = "-145px, -395px";this.items[12].translate = "-285px, -340px";break;
          case 12:this.items[12].translate = "-315px, -270px";this.items[0].translate = "-415px,-150px";break;
          case 13:this.items[0].translate = "-465px,-125px";this.items[1].translate = "-495px,85px";break;
          case 14: this.items[1].translate = "-310px,-255px";this.items[2].translate = "-419px,-155px";break;
        }
      } else {
        switch (this.count) {
          case 0:this.items[0].translate = "-340px, -295px";this.items[1].translate = "-475px, -130px";this.items[12].translate = "-110px, -310px";break;
          case 1:this.items[0].translate = "-315px,-325px";this.items[1].translate = "-430px,-95px";this.items[2].translate = "-475px,145px";this.items[12].translate = "-280px, -220px";break;
          case 2:this.items[2].translate = "-420px,160px";this.items[3].translate = "-350px,350px";break;
          case 3:this.items[3].translate = "-300px,350px";this.items[2].translate = "-445px,100px";this.items[4].translate = "-130px,475px";break;
          case 4:this.items[3].translate = "-320px,320px";this.items[4].translate = "-95px, 455px";this.items[5].translate = "125px,480px";break;
          case 5:this.items[4].translate = "-120px,425px";this.items[5].translate = "150px, 425px";this.items[6].translate = "340px,355px";break;
          case 6:this.items[5].translate = "125px,395px";this.items[6].translate = "340px, 325px";this.items[7].translate = "480px,140px";this.items[8].translate = "435px,-110px";break;
          case 7:this.items[6].translate = "300px, 300px";this.items[7].translate = "445px, 85px";this.items[8].translate = "475px,-115px";break;
          case 8:this.items[7].translate = "400px, 120px";this.items[8].translate = "435px, -155px";this.items[9].translate = "360px, -350px";break;
          case 9:this.items[8].translate = "435px, -40px";this.items[9].translate = "310px, -350px";this.items[10].translate = "135px, -475px";break;
          case 10:this.items[9].translate = "320px, -335px";this.items[10].translate = "95px, -450px";this.items[11].translate = "-115px, -480px";this.items[12].translate = "-345px, -400px";break;
          case 11:this.items[10].translate = "110px, -410px";this.items[11].translate = "-140px, -435px";this.items[0].translate = "-350px, -340px";this.items[12].translate = "-480px, -125px";break;
          case 12:this.items[11].translate = "-100px, -340px";this.items[12].translate = "-480px, -125px";this.items[1].translate = "-480px, 125px";this.items[0].translate = "-330px, -280px";this.items[3].translate = "-480px, 100px";break;
          case 13:this.items[12].translate = "-430px, -80px";this.items[0].translate = "-300px, -280px";this.items[1].translate = "-480px, 155px";this.items[2].translate = "-380px, 425px";this.items[3].translate = "-300px, 725px";break;
          case 14:this.items[1].translate = "-410px, 165px";this.items[2].translate = "-355px, 370px";this.items[0].translate = "-390px, -90px";this.items[3].translate = "-160px, 500px";break;
          case -1:this.items[12].translate = "-110px, -410px";this.items[11].translate = "90px, -360px";this.items[10].translate = "125px, -400px";this.items[0].translate = "-350px, -330px";
        }
      }
    },
  },
  methods: {
    minus() {
      if (this.count == 12) this.count = 0;
      this.count++;
      // this.pageScroll()
      this.ref_.carousel.slideNext()
    },
    plus() {
      if (this.count == 0) this.count = 12;
      this.count = this.count - 1;
      // this.pageScroll()
      this.ref_.carousel.slidePrev()
    },
    scale() {
      this.scalling = !this.scalling;
    },
    handleScroll(event) {
      if (event.deltaY < 0) {
        setTimeout(()=>{

          this.minus()
        },500)
      
      } else if (event.deltaY > 0) {
        setTimeout(()=>{

          this.plus()
        },500)
        
      }
    },
  },
  mounted() {
    if (window.innerWidth < 1281 && window.innerWidth > 1101) {
      this.items[1].translate = "-460px,-175px";
    } else if (window.innerWidth < 1100 ) {
      this.items[0].translate = "-310px,-255px";
      this.items[1].translate = "-419px, -155px";
    }
  },
};
</script>