<template>
  <div id="app">
    <div class="sections" :class="menu?'open':''">
      <div class="menu-burger">
        <button class="close" @click="close"><img src="@/assets/icons/close.svg" alt=""></button>
        <div class="lang_phone">
          <div class="choose_lang">
            <a v-for="el,index in langs" :key="index" :class="{'router-link-active':currLang.code==el.code}" @click="lang(el)">{{el.name}}</a>
          </div>
          <div class="sos_call">
            <button @click="calling" class="phone"><img src="@/assets/icons/menu_phone.svg" alt=""></button> 
            <div class="menu-items call_center" v-if="menu2">
              <div class="menu-item"><a href="tel:+998712321002"><p>{{$t("Tashkent")}}:</p> +998(71)2321002</a></div>
              <div class="menu-item"><a href="tel:+998707624029"><p>{{$t("regTashkent")}}:</p> +998(70)7624029</a></div>
              <div class="menu-item"><a href="tel:+998662235354"><p>{{$t("regSamarkand")}}:</p> +998(66)2235354</a></div>
              <div class="menu-item"><a href="tel:+998755212505"><p>{{$t("regKashkadarya")}}:</p> +998(75)5212505</a></div>
              <div class="menu-item"><a href="tel:+998762290145"><p>{{$t("regSurkhandarya")}}:</p> +998(76)2290145</a></div>
              <div class="menu-item"><a href="tel:+998652204180"><p>{{$t("regBukhoro")}}:</p> +998(65)2204180</a></div>
              <div class="menu-item"><a href="tel:+998622204408"><p>{{$t("regKhorezm")}}:</p> +998(62)2204408</a></div>
              <div class="menu-item"><a href="tel:1173"><p>{{$t('repubShortNum')}}</p><span>1173</span></a></div>
            </div> 
          </div>
        </div>
        <div class="creator">
          <div class="c-logo">
            <span>{{$t("authSite")}}</span>
            <img v-if="$i18n.locale=='en'" src="@/assets/icons/logoen.svg" alt="">
            <img v-else src="@/assets/icons/logoru.svg" alt="">
          </div>
        </div>
      </div>
      <hooper :autoPlay="true" :playSpeed="150000" :hoverPause="false"  ref="carousel" @slide="updateCarousel" :vertical="true" :infiniteScroll="true">
        <slide v-for="item,index in items" :key="index">
        <div class="MemoTourist">
            <div class="content">
              <div class="MemoTourist_center">
                <div class="h-85">
                  <div class="header">
                    <b>{{item.textb[$i18n.locale]}}</b>
                    <p>{{item.textp[$i18n.locale]}}</p>
                  </div>
                  <img :src="item.img" alt=""></div>
                  <a :href="item.ahref" >{{$t('more')}}</a>
                </div>
              </div>
          </div>
        </slide>
      </hooper>
      <Default :open="menu" @openMenu="open_menu($event)"/>
      <carousel :slide="activeSection" :ref_="$refs"/>
    </div>
  </div>
</template>

<script>
import carousel from '../components/carousel'

export default {
  name: 'App',
  data(){
    return{
      activeSection: 1,
      currLang: {name:'O\'z',code:'uz'},
      langs:[
          {name: "En",code:"en"},
          {name: "Ру",code:"ru"},
          {name: "O'z",code:"uz"},
      ],
      menu: false,
      menu2: true,
      items: [
        {
          img: require("@/assets/img/passport.png"),
          textb: {ru:"Памятка",en:"Tourist",uz:'Turistik'},
          textp: {ru:" туриста",en:" memo",uz:' eslatma'},
          ahref: "/pdf"
          // ahref: "https://uzbekistan.travel/ru/pamyatka-turistu/"
},
        {
          img: require("@/assets/img/6c191b3de6a40f6568eaa02e73bfce9d2 1 1.png"),
          textb: {ru:"Онлайн услуги",en:"Online banking",uz:'Onlayn bank'},
          textp: {ru:"банков",en:"services",uz:'xizmatlari'},
          ahref: "https://apelsin.uz/ru"
},
        {
          img: require("@/assets/img/widget_1569244024_4999 2.png"),
          textb: {ru:"Расположение",en:"Location ",uz:'Bankomatlarning '},
          textp: {ru:"банкоматов",en:"of ATMs",uz:'joylashuvi'},
          ahref: "https://cbu.uz/ru/credit-organizations/banks/atm/"
},
        {
          img: require("@/assets/img/1030520982 2.png"),
          textb: {ru:"Аренда авто и",en:"Car rental",uz:'Avtomobil ijarasi'},
          textp: {ru:"многое другое",en:"and more",uz:'va boshqalar'},
          ahref: "https://rentcar.uz/ru/cars/?utm_source=google&utm_medium=cpc&utm_campaign=rentcar_arenda_avto_marki&utm_content={ad_id}&utm_term=%D0%B2%D0%B7%D1%8F%D1%82%D1%8C%20%D0%B2%20%D0%BE%D1%80%D0%B5%D0%BD%D0%B4%D1%83%20%D0%B0%D0%B2%D1%82%D0%BE"
},
        {
          img: require("@/assets/img/anyconv 1.png"),
          textb: {ru:"Покупка",en:"Buying ",uz:'Aviachiptalarni '},
          textp: {ru:"авиа-билетов",en:"air tickets",uz:'sotib olish'},
          ahref: "https://book.uzairways.com/ru/?806"
},
        {
          img: require("@/assets/img/Talgo-250_UTY-Afrosiyob_Railcolornews_0384 2.png"),
          textb: {ru:"Покупка",en:"Buying ",uz:'Temir yo\'l chiptalarini '},
          textp: {ru:" ж/д билетов",en:"railway tickets",uz:'sotib olish'},
          ahref: "https://chipta.railway.uz/ru/home"
},
        {
          img: require("@/assets/img/сумка узбекистан 1.png"),
          textb: {ru:"Турпакеты для",en:"Tour packages ",uz:'Faol turizm uchun '},
          textp: {ru:"активного туризма",en:"for active tourism",uz:'tur paketlar'},
          ahref: "https://uzbekistan.travel/ru/c/turisticheskie-marshruti/"
},
        {
          img: require("@/assets/img/1853395_original 1.png"),
          textb: {ru:"Информация о",en:"Information about ",uz:'Diqqatga sazovor maskan '},
          textp: {ru:"достопримечательностях",en:"sightseeing",uz:'haqida ma\'lumot'},
          ahref: "https://map.uzbekistan.travel/ru"
},
        {
          img: require("@/assets/img/figurka-keramicheskaya-topchan-kruglyy-14-sm-miks_0c58dea97aca0fc_1024x3000.png"),
          textb: {ru:"Расположение сувенирных ",en:"Location of ",uz:'Suvenir do\'konlarining '},
          textp: {ru:"магазинов",en:"souvenir shops",uz:'joylashuvi'},
          ahref: "https://hunar.uz/pub/tourist_activity"
},
        {
          img: require("@/assets/img/gestion_reservas 1.png"),
          textb: {ru:"Бронирование номеров",en:"Booking ",uz:'Mehmonxona xonalarini'},
          textp: {ru:"в гостиницах",en:"hotel rooms ",uz:'bron qilish'},
          ahref: "https://www.booking.com/searchresults.ru.html?label=gen173nr-1FCAEoggI46AdIM1gEaO4BiAEBmAEhuAEXyAEM2AEB6AEB-AELiAIBqAIDuALspPyXBsACAdICJDVmM2QzNjhlLTU4Y2MtNDJiMC05MjFmLWUzYjM1NmMwNTViY9gCBuACAQ&sid=027fe30eadc0f12c2f7c598023a8e168&sb=1&sb_lp=1&src=index&src_elem=sb&error_url=https%3A%2F%2Fwww.booking.com%2Findex.ru.html%3Flabel%3Dgen173nr-1FCAEoggI46AdIM1gEaO4BiAEBmAEhuAEXyAEM2AEB6AEB-AELiAIBqAIDuALspPyXBsACAdICJDVmM2QzNjhlLTU4Y2MtNDJiMC05MjFmLWUzYjM1NmMwNTViY9gCBuACAQ%26sid%3D027fe30eadc0f12c2f7c598023a8e168%26sb_price_type%3Dtotal%26%26&ss=%D0%A3%D0%B7%D0%B1%D0%B5%D0%BA%D0%B8%D1%81%D1%82%D0%B0%D0%BD&is_ski_area=&checkin_year=&checkin_month=&checkout_year=&checkout_month=&group_adults=2&group_children=0&no_rooms=1&b_h4u_keep_filters=&from_sf=1&ss_raw=%D1%83%D0%B7%D0%B1%D0%B5%D0%BA%D0%B8%D1%81%D1%82%D0%B0%D0%BD&ac_position=0&ac_langcode=ru&ac_click_type=b&dest_id=226&dest_type=country&place_id_lat=41.321&place_id_lon=62.552&search_pageview_id=4b6a1ff6502f0034&search_selected=true&search_pageview_id=4b6a1ff6502f0034&ac_suggestion_list_length=2&ac_suggestion_theme_list_length=0"
},
        {
          img: require("@/assets/img/1645301803_31-phonoteka-org-p-fon-dlya-turisticheskogo-saita-33 1.png"),
          textb: {ru:"Страхования во ",en:"Travel ",uz:'Sayohat '},
          textp: {ru:"время путешествия",en:"insurance",uz:'sug\'urtasi'},
          ahref: "https://gross.uz/ru"
},
        {
          img: require("@/assets/img/spoon-knife-and-fork-with-red-ribbon 1.png"),
          textb: {ru:"Информация о кафе",uz:"Kafe va",en:'Information about'},
          textp: {ru:"и ресторанах",uz:"restoranlar haqida ",en:'cafes and restaurants'},
          ahref: "https://map.uzbekistan.travel/ru"
},
        {
          img: require("@/assets/img/6e03df6176b6bb51f91784e04fd76130 1.png"),
          textb: {ru:"Профессиональные",en:"Professional ",uz:'Professional gidlar'},
          textp: {ru:"гиды",en:"guides",uz:'va yo’lboshlovchilar'},
          ahref: "https://needguide.ru/search_guide.php?country=%D0%A3%D0%B7%D0%B1%D0%B5%D0%BA%D0%B8%D1%81%D1%82%D0%B0%D0%BD"
},
        {
          img: require("@/assets/img/AdobeStock_418085646_Preview.png"),
          textb: {ru:"Доставки",en:"Delivery",uz:'Yetkazib berish'},
          textp: {ru:"",en:"",uz:''},
          ahref: "https://express24.uz/"
},
//         {
//           img: require("@/assets/img/spoon-knife-and-fork-with-red-ribbon 1.png"),
//           textb: "Информация о кафе",
//           textp: " и ресторанах",
//           ahref: "https://map.uzbekistan.travel/ru"
// },
      ],
    }
  },
  methods:{
      open_menu(e){
        this.menu = e
      },
      close(){
        this.menu = false
        document.body.classList.remove('my-class')
      },
      calling(){
          this.menu2 = !this.menu2        
      },
      updateCarousel(payload){
        this.activeSection = payload.currentSlide
      },
      lang(e){
        this.currLang = e
                this.$router.push({
                 query: {
                ...this.$router.query,
                lang: e.code,
            },
            });
        this.$i18n.locale = e.code
        this.$router.go()
      }
  },
  components: {
    carousel,
  },
  mounted(){
        this.currLang = this.langs.find((el)=>(this.$route.query.lang||'uz')==el.code)
        this.$i18n.locale = this.$route.query.lang||'uz'
  }
}
</script>

<style lang="scss">
@import '../style/styles.scss'
// @import '../style/styles.scss'

</style>
