<template>
        <iframe src="PamyatkaDlyaTuristarus.pdf"></iframe>
       <!-- <vue-pdf-app pdf="PamyatkaDlyaTuristarus.pdf" @open="openHandler"></vue-pdf-app> -->
</template>
<script>

export default {
  name: "Basic",
  methods: {
    openHandler(pdfApp) {
      window._pdfApp = pdfApp;
    },
  },
};
</script>