<template>
    <div class="header_main">
        <div class="logo"><img src="@/assets/icons/logo.svg" alt=""></div>
        <div class="burger" @click="openMenu">
          <img src="@/assets/icons/burger.svg" alt="">
        </div>
        <!-- <div class="disabilities"><img src="@/assets/icons/glass.svg" alt=""></div> -->
        <div class="lang_phone">
          <div class="sos_call">
            <button @click="changeLang"><img src="@/assets/icons/globus.svg" alt="">{{currLang.name}} <img src="@/assets/icons/arrow-down.svg" alt=""></button>
            <div class="menu-items" v-if="menu">
              <div class="menu-item" v-for="el,index in langs" :key="index"><a :class="{'router-link-active':currLang.code==el.code}" @click="lang(el)">{{el.name}}</a></div>
            </div> 
          </div>
          <div class="sos_call">
            <button @click="calling" class="phone"><img src="@/assets/icons/phone.svg" alt=""></button> 
            <div class="menu-items call_center" v-if="menu2">
              <div class="menu-item"><a href="tel:+998712321002"><p>{{$t("Tashkent")}}:</p> 71  2321002</a></div>
              <div class="menu-item"><a href="tel:+998707624029"><p>{{$t("regTashkent")}}:</p>  70  7624029</a></div>
              <div class="menu-item"><a href="tel:+998662235354"><p>{{$t("regSamarkand")}}:</p>  66  2235354</a></div>
              <div class="menu-item"><a href="tel:+998755212505"><p>{{$t("regKashkadarya")}}:</p>  75  5212505</a></div>
              <div class="menu-item"><a href="tel:+998762290145"><p>{{$t("regSurkhandarya")}}:</p>  76  2290145</a></div>
              <div class="menu-item"><a href="tel:+998652204180"><p>{{$t("regBukhoro")}}:</p>  65  2204180</a></div>
              <div class="menu-item"><a href="tel:+998622204408"><p>{{$t("regKhorezm")}}:</p>  62  2204408</a></div>
              <div class="menu-item"><a href="tel:1173"><p>{{$t('repubShortNum')}}</p><span>1173</span></a></div>
            </div> 
          </div>
        </div>
        <div id="left_text" class="h3">
         <h3 >{{$t('touristServices1')}}</h3>
         <h4> {{$t('touristServices2')}}</h4>
        </div>
    </div>
</template>
<script>
export default {
  props:{
    open:{
      type: Boolean,
      default() {
                return false;
            },
    }
  },
  data(){
    return{
        menu: false,
        menu2: false,
        menu3: false,
        currLang: {name:'O\'z',code:'uz'},
        langs:[
            {name: "En",code:"en"},
            {name: "Ру",code:"ru"},
            {name: "O'z",code:"uz"},
        ]
    }
  },
  watch:{
    open(){
      this.menu3 = this.open
    }
  },
  methods:{
    changeLang(){
        this.menu = !this.menu        
        this.menu2 = false
    },
    calling(){
        this.menu2 = !this.menu2        
        this.menu = false
    },
    openMenu(){
      document.body.classList.add('my-class')
      this.menu3 = !this.menu3
      this.$emit("openMenu",this.menu3)
      // const item = document.getElementsByClassName('uwy')
      // item.classList.add("behind")
    },
    lang(e){
      this.currLang = e
                this.$router.push({
                 query: {
                ...this.$router.query,
                lang: e.code,
            },
            });
        this.$i18n.locale = e.code
        this.menu = false
        this.$router.go()
    }
  },
  mounted(){
        this.currLang = this.langs.find((el)=>(this.$route.query.lang||'uz')==el.code)
        this.$i18n.locale = this.$route.query.lang||'uz'
  }
}
</script>