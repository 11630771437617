<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import Main from './views/main.vue'
  
  export default {
    name: 'app',
    components: {
        Main
    },
    watch: {
      $route: {
        handler: function(value) {
          if(value.name=='pdf') document.body.classList.add('pdf')
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
    layout() {
      return (this.$route.meta.layout || 'additional') + '-layout'
    },
  },
  mounted(){
    if(window.innerWidth > 1400){
      if(this.$route.query.lang == 'ru'){
          document.getElementById('left_text').style.left = "-120px"
      }
    }
    else if(window.innerWidth > 1200){
      if(this.$route.query.lang == 'ru'){
          document.getElementById('left_text').style.left = "-95px"
      }
    }
    else if(window.innerWidth > 1000){
      if(this.$route.query.lang == 'ru'){
          document.getElementById('left_text').style.left = "-80px"
      }
    }else if(window.innerWidth > 760){
      if(this.$route.query.lang == 'ru'){
          document.getElementById('left_text').style.left = "-60px"
      }
    }else {
      if(this.$route.query.lang == 'ru'){
          document.getElementById('left_text').style.left = "0"
      }
    }
    // console.log(this.$route);
  }
}
</script>

<style lang="scss">
@import './src/style/styles.scss'

</style>
