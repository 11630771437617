const lang = {
  uz:{
    more: 'Ko\'proq',
    authSite:'Sayt yaratuvchisi',
    Tashkent:'Toshkent shahar',
    regTashkent:'Toshkent viloyati',
    regSamarkand:'Samarqand viloyati',
    regKashkadarya:'Qashqadaryo viloyati',
    regSurkhandarya:'Surxondaryo viloyati',
    regBukhoro:'Buxoro viloyati',        
    regKhorezm:'Xorazm viloyati',
    repubShortNum: 'Respublika qisqa raqami',
    touristServices1:'Turistik',
    touristServices2:'xizmatlar'
  },
  ru:{
    more: 'Подробнее',
    authSite:'Разработка сайта',
    Tashkent:'город Ташкент',
    regTashkent:'Ташкентская область',
    regSamarkand:'Самаркандская область',
    regKashkadarya:'Кашкадарьинская область',
    regSurkhandarya:'Сурхандарьинская область',
    regBukhoro:'Бухарская область',        
    regKhorezm:'Хорезмская область',
    repubShortNum: 'Короткий номер республика',
    touristServices1:'Туристические',
    touristServices2:'услуги'
  },
  en:{
    more: 'More',
    authSite:'Website development',
    Tashkent:'Tashkent city',
    regTashkent:'Tashkent region',
    regSamarkand:'Samarkand region',
    regKashkadarya:'Kashkadarya region',
    regSurkhandarya:'Surkhandarya region',
    regBukhoro:'Bukhara region',        
    regKhorezm:'Khorezm region',
    repubShortNum: 'Republic short number',
    touristServices1:'Tourist',
    touristServices2:'services'
  }
};
export default lang;
